<template>
  <div>
    <div class="section section__company">
      <div class="section__wrapper">
        <div class="title-with-icon title-with-icon_menu mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_back"></div>
          <div class="title-with-icon__title"><a href="company.html">Сведения об образовательной организации</a></div>
          <div class="title-with-icon__delimeter">/</div>
          <div class="title-with-icon__title">Материально-техническое обеспечение и оснащенность образовательного процесса
          </div>
        </div>

        <div class="mb-5 d-block d-md-none">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <div class="table-wrapper_indicator"></div>
            </div>
          </div>
        </div>

        <div class="table-wrapper table-wrapper_scrolled mb-7">
          <table class="table_bordered">
            <tbody>
            <tr itemprop="purposeCab">
              <td>Сведения об оборудованных учебных кабинетах</td>
              <td itemprop="addressCab">Две учебные аудитории по адресу: 123610, г. Москва, Краснопресненская наб., д. 12</td>
              <td>
                <a href="https://exporteduru.servicecdn.ru/media/default/0001/77/41acfb75bc12d21c1344bb3751eba74c12c86329.pdf" class="content_underline content_red">Договор аренды помещений</a><br/>
                <a href="https://exporteduru.servicecdn.ru/media/default/0001/76/faa8196d5ede71970728343520a41fb88f97a011.jpeg" class="content_underline content_red">Заключение СЭ</a>
              </td>
            </tr>
            <tr itemprop="purposePrac">
              <td>Сведения об объектах для проведения практических занятий</td>
              <td itemprop="addressPrac">Две учебные аудитории по адресу: 123610, г. Москва, Краснопресненская наб., д. 12</td>
              <td>
                <a href="https://exporteduru.servicecdn.ru/media/default/0001/77/41acfb75bc12d21c1344bb3751eba74c12c86329.pdf" class="content_underline content_red">Договор аренды помещений</a><br/>
                <a href="https://exporteduru.servicecdn.ru/media/default/0001/76/faa8196d5ede71970728343520a41fb88f97a011.jpeg" class="content_underline content_red">Заключение СЭ</a>
              </td>
            </tr>
            <tr itemprop="purposeLibr">
              <td>Сведения о библиотеке(ах)</td>
              <td>имеется</td>
              <td itemprop="objName">
                Электронная библиотека:<br/> <a href="/documents" class="content_underline content_red">https://exportedu.ru/documents</a>
              </td>
            </tr>
            <tr>
              <td>Сведения об объектах спорта</td>
              <td itemprop="purposeSport">отсутствуют</td>
              <td></td>
            </tr>
            <tr itemprop="purposeFacil">
              <td>Сведения о средствах обучения и воспитания</td>
              <td>имеется</td>
              <td>Ноутбуки <strong class="content_700">49 шт.</strong> Проектор <strong class="content_700">1 шт.</strong>
                Принтер <strong class="content_700">1 шт.</strong> Доска магнитно-маркерная <strong class="content_700">1
                  шт.</strong>
              </td>
            </tr>
            <tr itemprop="meals">
              <td>Сведения об условиях питания обучающихся</td>
              <td>имеется</td>
              <td><a href="https://exporteduru.servicecdn.ru/media/default/0001/76/1a1cc81abb00867ee80f62d0f861e496324076bf.pdf" class="content_underline content_red">Договор об организации питания</a></td>
            </tr>
            <tr itemprop="health">
              <td>Сведения об условиях охраны здоровья обучающихся</td>
              <td>имеется</td>
              <td><a href="https://exporteduru.servicecdn.ru/media/default/0001/76/7126e143b8fab452ced43e36136f9c010dac24b9.pdf" class="content_underline content_red">Положение об охране здоровья слушателей</a></td>
            </tr>
            <tr itemprop="comNet">
              <td>Сведения о доступе к информационным системам и информационно-телекоммуникационным сетям</td>
              <td>имеется</td>
              <td><a href="https://exporteduru.servicecdn.ru/media/default/0001/76/3235fd746b85b15c4d82bfe7537f8493329dca1e.pdf" class="content_underline content_red">Положение об образовательном портале</a></td>
            </tr>
            <tr itemprop="eoisOwn">
              <td>Сведения собственных электронных образовательных и информационных ресурсов</td>
              <td>имеется</td>
              <td>На сайте Школы экспорта реализован функционал для дистанционного обучения: <a href="/education#online" class="content_underline content_red">https://exportedu.ru/education#online</a>
              </td>
            </tr>
            <tr itemprop="eoisSide">
              <td>Сведения сторонних электронных образовательных и информационных ресурсов</td>
              <td>отсутствуют</td>
              <td></td>
            </tr>
            <tr itemprop="bdec">
              <td>Сведения о базе данных электронного каталога</td>
              <td>отсутствуют</td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="content_title content_700 mb-24">На метро:</div>
        <div class="content_18 mb-24">Метро «Улица 1905 года» (выход к ул. Красная Пресня). Автобус №423 от метро «Улица
          1905 года» (заезжает на территорию ЦМТ). Путь пешком занимает около 20 минут (примерно 1 км): следует идти вниз по
          улице 1905 года по направлению к Краснопресненской набережной до комплекса зданий Центра международной торговли.
        </div>

        <div class="content_title content_700 mb-24">На автомобиле:</div>
        <div class="content_18 mb-24">Въезд со стороны Краснопресненской набережной через КПП -1 до Конгресс-центра (подъезд 4) </div>

        <div class="section__company-plan d-flex mb-7">
          <div class="plan-1"><img class="" src="/pic/company-plan-1.png" /></div>
          <div class="plan-2"><img class="" src="/pic/company-plan-2.png" /></div>
        </div>

        <div class="content_title content_700 mb-24">Фото залов в ЦМТ (7 подъезд)</div>
        <div class="container-grid grid-3 section__company-gallery mb-2 d-none d-md-flex">
          <div class="container-grid__item">
            <img src="/pic/gallery-1.png" class="object-fit-cover" />
          </div>
          <div class="container-grid__item">
            <img src="/pic/gallery-2.png" class="object-fit-cover" />
          </div>
          <div class="container-grid__item">
            <img src="/pic/gallery-3.png" class="object-fit-cover" />
          </div>
        </div>

        <div class="section__company-gallery-mobile mb-2 d-block d-md-none">
          <div class="owl-carousel">
            <div class="item"><img src="/pic/gallery-1.png" class="object-fit-cover" /></div>
            <div class="item"><img src="/pic/gallery-2.png" class="object-fit-cover" /></div>
            <div class="item"><img src="/pic/gallery-3.png" class="object-fit-cover" /></div>
          </div>
        </div>


        <div class="content_title content_700 mb-24">Фото учебного класса (9 подъезд) </div>
        <div class="container-grid grid-3 section__company-gallery mb-10 d-none d-md-flex">
          <div class="container-grid__item">
            <img src="/pic/gallery-4.png" class="object-fit-cover" />
          </div>
          <div class="container-grid__item">
            <img src="/pic/gallery-5.png" class="object-fit-cover" />
          </div>
          <div class="container-grid__item">
            <img src="/pic/gallery-6.png" class="object-fit-cover" />
          </div>
        </div>

        <div class="section__company-gallery-mobile mb-3 d-block d-md-none">
          <div class="owl-carousel">
            <div class="item"><img src="/pic/gallery-4.png" class="object-fit-cover" /></div>
            <div class="item"><img src="/pic/gallery-5.png" class="object-fit-cover" /></div>
            <div class="item"><img src="/pic/gallery-6.png" class="object-fit-cover" /></div>
          </div>
        </div>
        <div class="my-5 d-block d-md-none">
          <a class="history-back" href="/sveden">Вернуться</a>
        </div>
        <div class="section__subsection_blue section__company-nav">
          <div class="section__subsection-wrapper">
            <company-navigation />
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../../components/PageFooter";
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoMaterial",
  components: {CompanyNavigation, PageFooter},
  mounted() {
    this.$parent.init()
  }
}
</script>

<style lang="scss">
  .section__company {
    h4 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-top: 7rem!important;
      &.no-margin {
        margin: -40px 0 20px!important;
      }
    }
    .content_18 {
      a {
        color: #ed1b2f;
        text-decoration: underline;
      }
    }
  }
</style>